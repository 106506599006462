<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      title: "Materi",
      items: [
        {
          text: "Dashboard",
          href: "/",
          active: true,
        },
      ],
      user_name: localStorage.session_name,
      user_profile: {},
      idjadwal: this.$route.params.id,
      data_jadwal: "",
      data_simulasi: "",
      petugas_kelas: "",
      url_storage: process.env.VUE_APP_BACKEND_URL,
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      let self = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users/" + localStorage.session_user_id + "/profile",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config).then((response) => {
        let response_data = response.data.data;
        self.user_profile = response_data.master_user;
        this.getDataSimulasi();
      });
    },

    getDataSimulasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "detail-jadwal/" + self.idjadwal;
      var config = {
        method: "get",
        url: url,
        params: {
          nip: self.user_profile.u_nip,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.data_jadwal = response_fix.jadwal;
            self.data_simulasi = response_fix.data;
            self.petugas_kelas = JSON.parse(self.data_jadwal.j_json_petugas);
            console.log(this.data_simulasi);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
          self.loadingTable = false;
        });
    },
    mulaiMengerjakan(id_jadwal_detail, tipe, tipe_pengerjaan) {
      Swal.fire({
        title: "Perhatian",
        text: "Apakah anda yakin ingin mulai mengerjakan?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(tipe_pengerjaan);
          this.$router.push({
            name: "detail-jadwal-jawaban-asesi",
            params: {
              id_jadwal: this.$route.params.id,
              id_jadwal_detail: id_jadwal_detail,
              tipe: tipe,
              tipe_pengerjaan: tipe_pengerjaan,
            },
          });
        } else {
          Swal.close();
        }
      });
    },

    checkTanggal(tanggal_mulai, tanggal_selesai) {
      const start = Date.parse(tanggal_mulai);
      const end = Date.parse(tanggal_selesai);
      const d = Date.now();

      return d >= start && d <= end; // true
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <h4>Detail Jadwal</h4>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  <h5 class="text-primary">Profil</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row mt-2">
              <div class="form-group">
                <label class="col-form-label col-lg-3"> NIP </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="user_profile != undefined">{{ user_profile.u_nip }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Nama </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">{{ user_name }}</label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Jabatan </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="user_profile != undefined">{{ user_profile.u_namajenjangjabatan }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Unit </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="user_profile != undefined">{{ user_profile.u_unit }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> ID Jadwal </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="data_jadwal != undefined">{{ data_jadwal.idjadwal }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Dari </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="data_jadwal != undefined">{{ data_jadwal.j_tanggal_mulai }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Sampai </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="data_jadwal != undefined">{{ data_jadwal.j_tanggal_sampai }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> PTL </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="data_jadwal != undefined">{{ data_jadwal.j_txt_ptl }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Petugas Kelas </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="data_jadwal != undefined">
                    <ul v-for="petugas in petugas_kelas" :key="petugas.m_user_id_petugas">
                      <li>{{ petugas.jp_txt_petugas }}</li>
                    </ul>
                  </span>
                  <span v-else> - </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="row">
          <div class="col-xl-12 col-sm-12" v-for="jadwal_simulasi in data_simulasi" :key="jadwal_simulasi">
            <div class="card">
              <div class="card-body">
                <div class="d-flex">
                  <div class="avatar-md me-4">
                    <span class="avatar-title rounded-circle bg-light text-danger font-size-16">
                      <img :src="url_storage + jadwal_simulasi.simulasi?.image" alt height="30" class="rounded-circle avatar-md" />
                    </span>
                  </div>

                  <div class="flex-grow-1 overflow-hidden">
                    <h5 class="text-truncate font-size-15">
                      <a class="text-dark">{{ jadwal_simulasi.simulasi?.nama_simulasi }}</a>
                    </h5>
                    <table>
                      <tr>
                        <td><b>Jam Mulai &nbsp;</b></td>
                        <td>
                          :
                          {{ jadwal_simulasi.trans_jadwal_batch.batch.tanggal_awal }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Jam Selesai&nbsp;</b></td>
                        <td>
                          :
                          {{ jadwal_simulasi.trans_jadwal_batch.batch.tanggal_akhir }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Status&nbsp;</b></td>
                        <td>
                          :
                          <b-badge v-if="jadwal_simulasi.status == 'berlangsung'" pill variant="warning">
                            {{ jadwal_simulasi.status }}
                          </b-badge>
                          <b-badge v-else-if="jadwal_simulasi.status == 'belum'" pill variant="danger">
                            {{ jadwal_simulasi.status }}
                          </b-badge>
                          <b-badge v-else pill variant="success">
                            {{ jadwal_simulasi.status }}
                          </b-badge>
                        </td>
                      </tr>
                      <tr v-if="data_jadwal.status != 'SUDAH' && checkTanggal(jadwal_simulasi.trans_jadwal_batch.batch.tanggal_awal, jadwal_simulasi.trans_jadwal_batch.batch.tanggal_akhir)">
                        <td>
                          <div v-if="jadwal_simulasi.intake_type == 'intaketype7'">
                            <button
                              class="btn-info btn mt-3 btn-sm"
                              v-if="
                                (jadwal_simulasi.status == 'belum' || jadwal_simulasi.status == 'berlangsung') &&
                                jadwal_simulasi.show_button_test == true &&
                                jadwal_simulasi.is_status_persiapan_done != 1
                              "
                              @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'persiapan_lgd')"
                            >
                              Persiapan
                            </button>
                            &nbsp;
                            <button
                              class="btn-primary btn mt-3 btn-sm"
                              v-if="
                                (jadwal_simulasi.status == 'belum' || jadwal_simulasi.status == 'berlangsung') &&
                                jadwal_simulasi.show_button_test == true &&
                                jadwal_simulasi.is_status_persiapan_done == 1
                              "
                              @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'diskusi_lgd')"
                            >
                              Diskusi
                            </button>
                          </div>
                          <div v-else-if="jadwal_simulasi.intake_type == 'intaketype8'">
                            <div v-if="jadwal_simulasi.simulasi.simulasi_config.tipe_jawaban == 'Rich Text - Discussion'">
                              <button
                                class="btn-info btn mt-3 btn-sm"
                                v-if="
                                  (jadwal_simulasi.status == 'belum' || jadwal_simulasi.status == 'berlangsung') &&
                                  jadwal_simulasi.show_button_test == true &&
                                  jadwal_simulasi.is_status_persiapan_done != 1
                                "
                                @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'persiapan_interaksi')"
                              >
                                Persiapan
                              </button>
                              &nbsp;
                              <button
                                class="btn-primary btn mt-3 btn-sm"
                                v-if="
                                  (jadwal_simulasi.status == 'belum' || jadwal_simulasi.status == 'berlangsung') &&
                                  jadwal_simulasi.show_button_test == true &&
                                  jadwal_simulasi.is_status_persiapan_done == 1
                                "
                                @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'interaksi')"
                              >
                                Diskusi
                              </button>
                            </div>
                            <div v-else>
                              <button
                                class="btn-info btn mt-3 btn-sm"
                                v-if="
                                  (jadwal_simulasi.status == 'belum' || jadwal_simulasi.status == 'berlangsung') &&
                                  jadwal_simulasi.show_button_test == true &&
                                  jadwal_simulasi.is_status_persiapan_done != 1
                                "
                                @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'persiapan_interaksi')"
                              >
                                Persiapan
                              </button>
                              &nbsp;
                              <button
                                class="btn-primary btn mt-3 btn-sm"
                                v-if="
                                  (jadwal_simulasi.status == 'belum' || jadwal_simulasi.status == 'berlangsung') &&
                                  jadwal_simulasi.show_button_test == true &&
                                  jadwal_simulasi.is_status_persiapan_done == 1
                                "
                                @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'interaksi')"
                              >
                                Interaksi
                              </button>
                            </div>
                          </div>
                          <div v-else-if="jadwal_simulasi.intake_type == 'intaketype9'">
                            <button
                              class="btn-info btn mt-3 btn-sm"
                              v-if="
                                (jadwal_simulasi.status == 'belum' || jadwal_simulasi.status == 'berlangsung') &&
                                jadwal_simulasi.show_button_test == true &&
                                jadwal_simulasi.is_status_persiapan_done != 1
                              "
                              @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'persiapan')"
                            >
                              Persiapan
                            </button>
                            &nbsp;
                            <button
                              class="btn-primary btn mt-3 btn-sm"
                              v-if="
                                (jadwal_simulasi.status == 'belum' || jadwal_simulasi.status == 'berlangsung') &&
                                jadwal_simulasi.show_button_test == true &&
                                jadwal_simulasi.is_status_persiapan_done == 1
                              "
                              @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'presentasi')"
                            >
                              Presentasi
                            </button>
                          </div>
                          <div v-else>
                            <button
                              class="btn-primary btn mt-3 btn-sm"
                              v-if="jadwal_simulasi.status == 'belum' && jadwal_simulasi.show_button_test == true"
                              @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'tes')"
                            >
                              Mulai Mengerjakan
                            </button>
                            <button
                              class="btn-primary btn mt-3 btn-sm"
                              v-else-if="jadwal_simulasi.status == 'berlangsung' && jadwal_simulasi.show_button_test == true"
                              @click="mulaiMengerjakan(jadwal_simulasi.id, jadwal_simulasi.intake_type, 'tes')"
                            >
                              Lanjutkan Mengerjakan
                            </button>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
